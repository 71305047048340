<template>
  <div>
    <el-dialog
      title="编辑备注"
      :visible.sync="dialogTableVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="auto"
      >
        <el-form-item label="备注" prop="note">
          <el-input
            :rows="5"
            type="textarea"
            placeholder="请输入内容"
            v-model="formData.note"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button
          ref="submitButton"
          type="primary"
          @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/cashier.js";
export default {

  data() {
    return {
      dialogTableVisible: false,
      formData: {
        billGuid: "",
        softwareDate: "",
        note: "",
        recordType: "",
      },
      formRules: {
        note: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
    };
  },

  methods: {
    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
      // this.$emit("CloseDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$refs.submitButton.disabled = await true;
          let { data, errcode, errmsg } = await api.updateNote(this.formData);
          if (errcode == (await 0)) {
            await this.$message({
              message: "修改成功!",
              type: "success",
            });
            await this.$emit("success");
            await this.onCloseDialog("formRules");
          } else {
            await this.$message.error(errmsg);
          }
          this.$refs.submitButton.disabled = await false;
        }
      });
    },

    openNote(data = {}) {
      let { BillNote, BillGuid, SoftwareDate, RecordType } = data;
      this.formData.note = BillNote;
      this.formData.billGuid = BillGuid;
      this.formData.recordType = RecordType;
      this.formData.softwareDate = this.util.dateFormat(
        "YYYY-mm-dd",
        new Date(data.SoftwareDate * 1000)
      );
      this.dialogTableVisible = true;
    },

    closeNote() {
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 100% !important;
}
</style>