<template>
  <div>
    <el-dialog
      title="修改部门"
      :visible.sync="dialogTableVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="formRules" :model="submitData" label-width="auto">
        <el-form-item label="部门">
          <el-select
            v-model="submitData.deptGuid"
            placeholder="请选择部门"
            size="mini"
          >
            <el-option
              v-for="item in deptList"
              :key="item.DeptGuid"
              :label="item.DeptName"
              :value="item.DeptGuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog()">取 消</el-button>
        <el-button
          :disabled="submitDisabled"
          type="primary"
          @click="submitForm()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cashier from "@/api/cashier.js";

export default {
  props: {
    deptList: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      dialogTableVisible: false,
      submitDisabled: false,
      submitData: {
        deptGuid: "",
      },
      expeItemInfo: {},
    };
  },

  methods: {
    onCloseDialog() {
      this.dialogTableVisible = false;
    },

    onShowDialog(expeItemInfo) {
      this.expeItemInfo = expeItemInfo
      let param = this.deptList.find(item=>
        item.DeptName == expeItemInfo.DeptName
      )
      this.submitData.deptGuid = param ? param.DeptGuid : ''
      this.dialogTableVisible = true;
    },

    async submitForm() {
      let { expeItemInfo, submitData } = this;
      this.submitDisabled = true;
      try {
        submitData = {
          DeptGuid: submitData.deptGuid,
          BillGuid: expeItemInfo.BillGuid,
          ExpeItemGuid: expeItemInfo.InfoGuid,
          SoftwareDate: this.util.dateFormat( 'YYYY-mm-dd' , new Date(expeItemInfo.SoftwareDate * 1000))
        };
        let { data, errcode, errmsg } = await Cashier.updateExpeItemDept(
          submitData
        );
        if (errcode == 0) {
          this.$message({
            message: "修改成功!",
            type: "success",
          });
          this.$emit('success');
          this.onCloseDialog();
        }
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.submitDisabled = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 100% !important;
}
</style>
