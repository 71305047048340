<template>
  <div class="select-empl-dept-dialog">
    <!-- 选择开卡员工或部门对话框 -->
    <el-dialog
      title="修改业绩提成"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="6vh"
      :width="!util.isMobile() ? '800px' : '650px'"
      @close="onCloseDialog"
    >
      <div class="select-box">
        <!-- 选择员工 -->
        <!-- <div class="empl-box">
          <div class="top">
            <el-select
              v-model="emplActive"
              placeholder="选择员工"
              size="small"
              @change="onChangeSelect($event, 1)"
              @focus="popoverVisible = false"
            >
              <el-option
                v-for="(item, index) in emplList"
                :key="index"
                :label="
                  `${item.EmplNo}-${item.EmplName} (职位:${item.DutyName}, 级别:${item.LevelName})`
                "
                :value="index"
              >
              </el-option>
            </el-select>

            <div class="empl-roya">
                <el-switch
                  :value="submitData.AvgRoya ? true : false"
                  active-text="平分业绩提成"
                  @change="onChangeAvgRoya"
                >
                </el-switch>
              </div>
          </div>

          <div class="empl-list">
            <div
              class="empl-item"
              v-for="(item, index) in submitData.EmplRoyaList"
              :key="index"
            >
              <div class="info">
                {{ `${item.EmplName}-${item.DutyName}-${item.LevelName}` }}
              </div>

              <div class="content">
                <div class="input-item">
                  <span>可分配业绩：</span>
                  <span>{{ item.SharePerfor }}</span>
                </div>

                <div class="input-item">
                  <span>占比：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforRatio"
                    @change="onChangeInput(item, true, false)"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>

                <div class="input-item">
                  <span>业绩：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforMoney"
                    @change="onChangeInput(item, true, true)"
                  ></el-input>
                </div>

                <div class="input-item">
                  <span>可分配提成：</span>
                  <span>{{ item.ShareRoya }}</span>
                </div>

                <div class="input-item">
                  <span>占比：</span>
                  <el-input
                    size="mini"
                    v-model="item.RoyaRatio"
                    @change="onChangeInput(item, false, false)"
                  >
                    <template class="prent" slot="append">%</template>
                  </el-input>
                </div>

                <div class="input-item">
                  <span>提成：</span>
                  <el-input
                    size="mini"
                    v-model="item.RoyaMoney"
                    @change="onChangeInput(item, false, true)"
                  ></el-input>
                </div>
              </div>

              <div class="btn">
                <el-button
                  type="danger"
                  size="mini"
                  :disabled="deleteDisabled"
                  @click="onDeleteItem('EmplRoyaList', index)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </div> -->

        <!-- 选择部门 -->
        <div class="dept-box">
          <div class="top">
            <el-select
              v-model="deptActive"
              placeholder="选择部门"
              size="small"
              @change="onChangeSelect($event, 2)"
            >
              <el-option
                v-for="(item, index) in deptList"
                :key="item.DeptGuid"
                :label="item.DeptName"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>

          <div class="dept-list">
            <div
              class="dept-item"
              v-for="(item, index) in submitData.DeptRoyaList"
              :key="index"
            >
              <div class="info">{{ item.DeptName }}</div>

              <div class="content">
                <div class="input-item">
                  <span>占总业绩(%)：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforRatio"
                    @change="onChangeInput(item, true, false)"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>

                <div class="input-item">
                  <span>分享业绩：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforMoney"
                    @change="onChangeInput(item, true, true)"
                  ></el-input>
                </div>
              </div>

              <div class="btn">
                <el-button
                  type="danger"
                  size="mini"
                  :disabled="deleteDisabled"
                  @click="onDeleteItem('DeptRoyaList', index)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveOverallOpenKeepRoya"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cashier from "@/api/cashier.js";

export default {
  props: {},

  data() {
    return {
      active: 0,
      softwareDate: "",
      emplActive: "",
      deptActive: "",
      cardDefaultAmount: 0,
      cardDefaultPerfor: 0,
      emplList: [], // 员工列表
      deptList: [], // 部门列表
      // orgKeepEmpls: [],
      // orgKeepDepts: [],
      submitData: {
        BillGuid: "",
        AvgRoya: 0,
        EmplRoyaList: [],
        DeptRoyaList: [],
      },
      emplRoya: false,
      dialogVisible: false, // 对话框显示
      popoverVisible: false,
      deleteDisabled: false,
    };
  },

  methods: {
    // 初始化业绩提成数据
    async initOverallOpenKeepRoya() {
      try {
        let { data } = await Cashier.initOverallOpenKeepRoya({
          bill_guid: this.submitData.BillGuid,
          software_date: this.softwareDate,
        });
        this.emplList = data.emplList;
        this.deptList = data.deptList;
        this.cardDefaultAmount = data.cardDefaultAmount;
        this.cardDefaultPerfor = data.cardDefaultPerfor;
        // this.orgKeepEmpls = data.orgKeepEmpls;
        // this.orgKeepDepts = data.orgKeepDepts;

        this.submitData.EmplRoyaList = data.editKeepEmpls || [];
        this.submitData.DeptRoyaList = data.editKeepDepts || [];
      } catch (err) {
        console.log(err);
      }
    },

    // 保存业绩提成
    async saveOverallOpenKeepRoya() {
      let { submitData } = this;
      if (
        submitData.DeptRoyaList.length == 0 &&
        submitData.EmplRoyaList.length == 0
      ) {
        return this.onCloseDialog();
      } else if (
        submitData.DeptRoyaList.reduce((sum, obj) => sum + obj.PerforRatio, 0) >
        100
      ) {
        return this.$message.error("部门占总业绩的总和不能超过100%");
      }

      try {
        let { errcode } = await Cashier.saveOverallOpenKeepRoya(
          this.submitData
        );
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // // 计算还款业绩提成
    // async calcuRepayRoya() {
    //   let submitData = JSON.parse(JSON.stringify(this.submitData));
    //   submitData = {
    //     ...submitData,
    //     RepayMoney: submitData.Payments[0].PayMoney,
    //   };
    //   delete submitData.Payments;
    //   try {
    //     submitData.AvgRoya = submitData.AvgRoya ? 1 : 0;
    //     let { data } = await Repay.calcuRepayRoya(submitData);
    //     this.submitData = {
    //       ...this.submitData,
    //       EmplRoyaList: data.emplRoyaList,
    //       DeptRoyaList: data.deptRoyaList,
    //     };
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },

    // 打开对话框
    onShowDialog(submitData = {}) {
      let param = JSON.parse(JSON.stringify(submitData));
      this.submitData.BillGuid = param.BillGuid;
      this.softwareDate = this.util.dateFormat(
        "YYYY-mm-dd",
        new Date(param.SoftwareDate * 1000)
      );
      this.initOverallOpenKeepRoya();
      this.dialogVisible = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.dialogVisible = false;
      this.resetData();
    },

    // 平分业绩提成
    onChangeAvgRoya(event) {
      this.submitData.AvgRoya = event;
      window.sessionStorage.setItem("AvgRoya", event ? 1 : 0);
      // this.calcuRepayRoya();
    },

    // 输入框改变事件
    onChangeInput(event, isPerfor, isRatio) {
      let moneyName = "RoyaMoney",
        ratioName = "RoyaRatio",
        share = "ShareRoya";

      if (isPerfor) {
        moneyName = "PerforMoney";
        ratioName = "PerforRatio";
        share = "SharePerfor";
      }

      // if (event[share] == 0) {
      //   event[ratioName] = 0;
      //   return (event[moneyName] = 0);
      // }

      if (isRatio) {
        // 计算占比
        event[moneyName] = Number(Number(event[moneyName]).toFixed(2));
        event[ratioName] = Math.floor((event[moneyName] / event[share]) * 100);
      } else {
        // 计算金额
        // event[ratioName] = Number(event[ratioName]);
        // event[moneyName] = Number(
        //   (event[share] * (event[ratioName] / 100)).toFixed(2)
        // );
        event[ratioName] = Number(event[ratioName]);
        event[moneyName] = Number(
          (this.cardDefaultAmount * (event[ratioName] / 100)).toFixed(2)
        );
      }
    },

    // 员工或部门下拉框改变事件
    onChangeSelect(index, type) {
      let keyName = "",
        text = "";

      let data = {},
        arr = [];

      if (type == 1) {
        keyName = "EmplRoyaList";
        text = "员工";
        data = this.emplList[index];
        arr = this.submitData.EmplRoyaList.filter(
          (item) =>
            item.EmplGuid == data.EmplGuid && item.LevelGuid == data.LevelGuid
        );
      } else {
        keyName = "DeptRoyaList";
        text = "部门";
        data = this.deptList[index];
        arr = this.submitData.DeptRoyaList.filter(
          (item) => item.DeptGuid == data.DeptGuid
        );
      }

      if (arr.length) {
        return this.$message.info(`该${text}已添加过~`);
      }

      this.submitData[keyName].push(data);

      if (type != 1) {
        this.calcuDept();
      }

      // this.calcuRepayRoya();
    },

    // 计算部门业绩提成
    calcuDept() {
      let deptRoyaList = JSON.parse(
          JSON.stringify(this.submitData.DeptRoyaList)
        ),
        PerforRatio = Math.floor((100 / deptRoyaList.length) * 100) / 100,
        PerforMoney =
          Math.floor(this.cardDefaultAmount * (PerforRatio / 100) * 100) / 100;
      deptRoyaList.forEach((item) => {
        item.PerforRatio = PerforRatio;
        item.PerforMoney = PerforMoney;
      });
      this.submitData.DeptRoyaList = deptRoyaList;
    },

    // 重置页面数据
    resetData() {
      this.emplActive = "";
      this.deptActive = "";
    },

    // 删除员工或部门事件
    onDeleteItem(type, index) {
      this.$delete(this.submitData[type], index);
      if (type == "DeptRoyaList") {
        this.calcuDept();
      }
      // this.calcuRepayRoya();
      this.resetData();
    },

    // 确认按钮点击事件
    onSubmitData() {
      this.$emit("confirm", this.submitData);
      this.onCloseDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.select-empl-dept-dialog {
  .select-box,
  .select-box > div {
    display: flex;
    flex-direction: column;
  }

  .select-box {
    > div {
      flex: 1;

      .top {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        padding: 20px;

        .history {
          flex: 1;

          > * {
            margin: 0 10px;
          }
        }

        .el-select {
          width: 50%;
        }
      }

      .empl-list,
      .dept-list {
        height: 260px;
        max-height: 260px;
        display: block;
        overflow-y: scroll;
        // display: flex;
        // flex-direction: column;

        .empl-item,
        .dept-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;

          > div {
            margin: 20px 0;
          }

          .content {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .input-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0 10px;

              ::v-deep .el-input {
                width: 100px !important;
              }

              ::v-deep .el-input-group__append {
                padding: 0 5px !important;
              }
            }
          }
        }
      }
    }
  }
}

.history-body {
  padding: 10px;
  .el-radio-group {
    margin-bottom: 10px;
  }
}
</style>
