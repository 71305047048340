<template>
  <el-dialog
    title="关联时间记录"
    :visible.sync="dialogVisible"
    width="35%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="relate-time-dialog">
      <el-table
        :data="relateTimelist"
        v-loading="loading"
        :max-height="500"
        stripe
        fit
        border
        size="mini"
      >
        <el-table-column label="开始时间">
          <template slot-scope="scope">
            {{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.StartTime * 1000)
              )
            }}
          </template>
        </el-table-column>

        <el-table-column label="结束时间">
          <template slot-scope="scope">
            {{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.FinishTime * 1000)
              )
            }}
          </template>
        </el-table-column>

        <el-table-column label="工作时长/分" prop="WorkMinute">
          <!-- <template slot-scope="scope">
            {{ scope.row.WorkMinute }}
          </template> -->
        </el-table-column>

        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="onSelectTime(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import Cashier from "@/api/cashier.js";

export default {
  data() {
    return {
      billInfo: {}, // 账单信息
      itemInfo: {}, // 项目信息
      emplInfo: {}, // 员工信息
      cache: 0, // 
      relateTimelist: [], // 关联时间列表
      loading: false, // 表格加载
      dialogVisible: false, // 对话框显示
    };
  },

  methods: {
    // 获取可关联的计时记录
    async getRelateTimeList() {
      this.loading = true;
      try {
        let submitData = {
          bill_guid: this.billInfo.BillGuid,
          software_date: this.billInfo.SoftwareDate,
          empl_guid: this.emplInfo.EmplGuid,
          duty_no: this.emplInfo.DutyNo,
        };
        let { data } = await Cashier.getRelateTimeList(submitData);
        this.cache = data.cache;
        this.relateTimelist = data.list;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    // 关联计时记录到账单
    onSelectTime(event) {
      let that = this
      // console.log(event, this.billInfo);
      
      this.$confirm(`确认后将无法修改，是否选择此时间`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(async (res) => {
        try {
          let submitData = {
            bill_guid: this.billInfo.BillGuid,
            expe_item_guid: this.itemInfo.InfoGuid,
            time_guid: event.TimeGuid,
            cache: this.cache
          };
          let { errcode } = await Cashier.relateBillToTime(submitData);
          if(errcode == 0){
            that.$message.success('水单时间关联成功')
            that.$emit('success');
            that.onCloseDialog()
          }
        } catch (error) {
          console.log(error);
        }
      });
    },

    // 打开对话框
    onShowDialog(billInfo = {}, itemInfo = {}, emplInfo = {}) {
      this.billInfo = billInfo;
      this.itemInfo = itemInfo
      this.emplInfo = emplInfo;
      this.getRelateTimeList();
      this.dialogVisible = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
