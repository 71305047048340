<template>
  <div class="alone-dona-item-table-dialog">
    <el-dialog
      title="单独赠送项目"
      :visible.sync="showAloneDonaItemTableDialog"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="alone-dona-item-table-box">
        <el-table :data="tableData" max-height="500">
          <el-table-column prop="MemberName" label="会员姓名"></el-table-column>
          <el-table-column prop="MemberPhone" label="电话"></el-table-column>
          <el-table-column prop="StoresName" label="所属门店"></el-table-column>
          <el-table-column prop="CardName" label="卡名"></el-table-column>
          <el-table-column prop="VipNo" label="卡号"></el-table-column>
          <el-table-column prop="ItemName" label="项目名称"></el-table-column>
          <el-table-column label="分组" width="80">
            <template slot-scope="scope">
              {{ uniteIndexChange(scope.row.UniteIndex) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="DonaCount"
            label="赠送次数"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="Consume"
            label="消耗额"
            width="80"
          ></el-table-column>
          <el-table-column label="无限次数" width="80">
            <template slot-scope="scope">
              {{ scope.row.InfiniteMark ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column label="有效期" width="80">
            <template slot-scope="scope">
              {{ scope.row.ValidDate ? dateFormat(scope.row.ValidDate) : "无" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="DonaBatch"
            label="赠送批次"
            width="80"
          ></el-table-column>
          <el-table-column label="赠送时间" width="80">
            <template slot-scope="scope">
              {{ dateFormat(scope.row.DonaTime) }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button ref="submitButton" type="primary" @click="onCloseDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      showAloneDonaItemTableDialog: false,
    };
  },

  computed: {
    uniteIndexChange() {
      return function (uniteIndex) {
        return this.util.uniteIndexChange(uniteIndex);
      };
    },

    dateFormat() {
      return function (timestamp) {
        return this.util.dateFormat("YYYY-mm-dd", new Date(timestamp * 1000));
      };
    },
  },

  methods: {
    onShowDialog(aloneDonaItemList) {
      this.tableData = aloneDonaItemList;
      console.log(this.tableData);
      this.showAloneDonaItemTableDialog = true;
    },

    onCloseDialog() {
      this.showAloneDonaItemTableDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>