import { render, staticRenderFns } from "./relate-time-dialog.vue?vue&type=template&id=65f58959&scoped=true&"
import script from "./relate-time-dialog.vue?vue&type=script&lang=js&"
export * from "./relate-time-dialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f58959",
  null
  
)

export default component.exports